<template>
    <v-container fluid>
        <headerVue :title="$t('cargaComplete')" />
        <v-row>
            <v-col cols="12">
                
                <br/>
                <br/>
               <h1>Se subio el archivo con éxito</h1>
            </v-col>

        </v-row>
    </v-container>
</template>
<script>
import headerVue from "../../components/header.vue"
export default {
  components: {
    headerVue,
  },
}
</script>
<style>
</style>